<template>
  <b-card>
    <p>
      {{ $t('firstAgreementTitle') }}
    </p>
    <p>{{ $t('forThis') }}</p>
    <ol>
      <li>{{ $t('agreementListOne') }}</li>
      <li>{{ $t('agreementListTwo') }}</li>
      <li>{{ $t('agreementListThree') }}</li>
    </ol>

    <div class="d-flex align-items-center mw-100 my-2">
      <feather-icon
        class="mr-1"
        icon="FileTextIcon"
        size="36"
      />
      <div class="mw-100">
        <div class="d-flex flex-column">
          <h6>{{ userData.is_signed ? 'Signed Agreement' : $t('agreement') }}</h6>
        </div>
      </div>
      <b-button
        size="sm"
        class="ml-5"
        @click="downloadContract"
      >
        <feather-icon
          icon="DownloadIcon"
          size="18"
        />
      </b-button>
    </div>
    <template v-if="!userData.is_signed">
      <drop-zone
        v-if="!file"
        :options="dropzoneOptions"
        @add-file="handleSignFile"
        @reset="handleSignFile"
      >
        <template #description>
          <div class="d-flex flex-column align-items-center">
            <h6 class="my-1">
              {{ $t('dropScanText', { fileName: 'Agreement' }) }}
            </h6>
            <span>
              {{ $t('onlyTypeFiles', { fileType: 'PDF'}) }}
            </span>
          </div>
        </template>
      </drop-zone>
      <template v-else>
        <hr />
        <UploadedFile
          :file="file"
          @remove-file="file=null"
        />
      </template>
    </template>
    <template>
      <div class="mt-2">
        <b-button
          variant="primary"
          :disabled="!file"
          @click="submit"
        >
          <span class="d-none d-sm-inline">
            {{ $t('sendForVerification') }}
          </span>
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
          :disabled="!file"
          @click="file = null"
        >
          <span class="d-none d-sm-inline">
            {{ $t('reset') }}
          </span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </template>
  </b-card>
</template>

<script>
import DropZone from '@/views/apps/components/DropZone.vue';
import { BButton, BCard } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import UploadedFile from '@/views/apps/components/uploader/UploadedFile.vue';

export default {
  name: 'UserEditTabAgreement',
  components: {
    UploadedFile,
    BButton,
    DropZone,
    BCard,
  },
  props: {
    userData: {
      type: Object,
    },
  },
  data() {
    return {
      dropzoneOptions: {
        previewsContainer: false,
        autoProcessQueue: false,
        acceptedFiles: 'application/pdf',
        url: 'localhost',
      },
      file: null,
    };
  },
  methods: {
    handleSignFile([file]) {
      this.file = file;
    },
    submit() {
      try {
        this.$store.dispatch('profile/addSignAgreement', { file: this.file, userId: this.userData.id });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'The Agreement was sent to manager. Please, wait for verification.',
            icon: 'EditIcon',
            variant: 'success',
          },
        });
      } catch (e) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'The agreement was not sent to the manager. Please write to support.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    async downloadContract() {
      const contract = await this.$store.dispatch('profile/getAgreement');

      const downloadUrl = window.URL.createObjectURL(contract);

      window.open(downloadUrl, '_blank');
    },
  },
};
</script>
