<template>
  <b-card
    class="px-2 pb-2"
    no-body
  >
    <p>
      {{ $t('firstAgreementTitle') }}
    </p>
    <p>{{ $t('forThis') }}</p>
    <ol>
      <li>{{ $t('agreementListOne') }}</li>
      <li>{{ $t('agreementListTwo') }}</li>
      <li>{{ $t('agreementListThree') }}</li>
      <li>{{ $t('agreementListFour') }}</li>
    </ol>
    <b-row>
      <b-col
        cols="12"
        md="6"
        lg="6"
      >
        <div
          v-if="profile.agreement_signed"
          class="d-flex align-items-center mw-100 my-2"
        >
          <feather-icon
            class="mr-1"
            icon="FileTextIcon"
            size="36"
          />
          <div class="mw-100">
            <div class="d-flex flex-column">
              <h6>{{ $t('agreement') }}</h6>
            </div>
          </div>
          <b-button
            size="sm"
            class="ml-5"
            @click="downloadContract"
          >
            <feather-icon
              icon="DownloadIcon"
              size="18"
            />
          </b-button>
        </div>
        <template v-if="!profile.agreement_signed">
          <template v-if="agreementFile">
            <UploadedFile
              :file="agreementFile"
              @remove-file="agreementFile=null"
            />
          </template>
          <template v-if="signFile">
            <UploadedFile
              :file="signFile"
              @remove-file="signFile=null"
            />
          </template>
          <drop-zone
            v-if="!agreementFile"
            :options="dropzoneOptions"
            @add-file="handleAgreementFile"
            @reset="resetFiles"
          >
            <template #description>
              <div class="d-flex flex-column align-items-center">
                <h6 class="my-1">
                  {{ $t('dropScanText', { fileName: 'Sign_Agreement'}) }}
                </h6>
                <span>
                  {{ $t('onlyTypeFiles', { fileType: 'PDF'}) }}
                </span>
              </div>
            </template>
          </drop-zone>
        </template>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="6"
      >
        <div
          v-if="profile.company.sign_image"
          class="d-flex align-items-center mw-100 my-2"
        >
          <feather-icon
            class="mr-1"
            icon="FileTextIcon"
            size="36"
          />
          <div class="mw-100">
            <div class="d-flex flex-column">
              <h6>{{ $t('sign') }}</h6>
            </div>
          </div>
          <b-button
            size="sm"
            class="ml-5"
            @click="downloadSignedAgreement"
          >
            <feather-icon
              icon="DownloadIcon"
              size="18"
            />
          </b-button>
        </div>
        <template v-if="!profile.company.sign_image">
          <drop-zone
            v-if="!signFile"
            :options="dropzoneOptionsSign"
            @add-file="handleSignFile"
            @reset="resetFiles"
          >
            <template #description>
              <div class="d-flex flex-column align-items-center">
                <h6 class="my-1">
                  {{ $t('dropImageText', { fileName: 'проштампованной подписи'}) }}
                </h6>
                <span>
                  {{ $t('onlyTypeFiles', { fileType: 'PNG'}) }}
                </span>
              </div>
            </template>
          </drop-zone>
        </template>
      </b-col>
    </b-row>
    <template>
      <div class="mt-2">
        <b-button
          variant="primary"
          :disabled="!agreementFile && !signFile"
          @click="submit"
        >
          <span class="d-none d-sm-inline">
            {{ $t('sendForVerification') }}
          </span>
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
          :disabled="!agreementFile"
          @click="resetFiles"
        >
          <span class="d-none d-sm-inline">
            {{ $t('reset') }}
          </span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </template>
  </b-card>
</template>

<script>
import TransactionsDocumentsList from '@/views/trading/trading-deals/transactions/TransactionsDocumentsList.vue';
import DropZone from '@/views/apps/components/DropZone.vue';
import {
  BButton, BCard, BRow, BCol,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import UploadedFile from '@/views/apps/components/uploader/UploadedFile.vue';

export default {
  name: 'UserEditTabAgreement',
  components: {
    UploadedFile,
    BButton,
    DropZone,
    BCard,
    BCol,
    BRow,
    TransactionsDocumentsList,
  },
  props: {
    userData: {
      type: Object,
    },
  },
  data() {
    return {
      dropzoneOptions: {
        previewsContainer: false,
        autoProcessQueue: false,
        acceptedFiles: 'application/pdf',
        url: 'localhost',
      },
      dropzoneOptionsSign: {
        previewsContainer: false,
        autoProcessQueue: false,
        acceptedFiles: 'image/png',
        url: 'localhost',
      },
      agreementFile: null,
      signFile: null,
    };
  },
  computed: {
    profile() {
      return this.$store.getters['profile/profile'];
    },
  },
  methods: {
    resetFiles() {
      this.signFile = null;
      this.agreementFile = null;
    },
    handleSignFile([file]) {
      this.signFile = file;
    },
    handleAgreementFile([file]) {
      this.agreementFile = file;
    },
    submit() {
      try {
        this.$store.dispatch('profile/addContract', { file: this.agreementFile, userId: this.userData.id });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'The Sign was sent to manager. Please, wait for verification.',
            icon: 'EditIcon',
            variant: 'success',
          },
        });
      } catch (e) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'The sign must be of type: png.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
      if (this.signFile) {
        try {
          this.$store.dispatch('profile/addSign', { file: this.signFile, userId: this.userData.id });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'The Agreement was sent to manager. Please, wait for verification.',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        } catch (e) {
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'The agreement was not sent to the manager. Please write to support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      }
    },
    async downloadContract() {
      const contract = await this.$store.dispatch('profile/getContract');

      const downloadUrl = window.URL.createObjectURL(contract);

      window.open(downloadUrl, '_blank');
    },
    async downloadSignedAgreement() {
      window.open(this.profile.company.sign_image.url, '_blank');
    },
  },
};
</script>
