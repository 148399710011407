import { ref, computed, watch } from '@vue/composition-api';
import store from '@/store';
import i18n from '@/libs/i18n';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { hasVerifiedRole, VERIFIED_ROLE } from '@/constants/roles.js';

export default function getOfferInfo() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'user', label: i18n.t('user'), sortable: true },
    { key: 'company', label: i18n.t('company'), sortable: true },
    { key: 'role', label: i18n.t('role'), sortable: true },
    { key: 'companyDetails', label: i18n.t('companyDetails'), sortable: true },
    { key: 'activation', label: i18n.t('activation'), sortable: true },
    { key: 'actions', label: i18n.t('actions') },
  ];
  const totalItems = computed(() => store.state.users.meta.total);
  const items = computed(() => store.state.users.users);

  const perPageOptions = [10, 25, 50, 100];
  const perPage = ref(perPageOptions[0]);
  const currentPage = ref(1);
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);

  const roleFilter = ref(null);
  const countryFilter = ref(null);
  const verificationFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    };
  });

  const fetchUsers = async () => {
    try {
      await store.dispatch('users/fetch', {
        per_page: perPage.value,
        page: currentPage.value,
        // TODO: remove
        // sort_by: sortBy.value,
        is_sort_dir_desc: isSortDirDesc.value,
        term: searchQuery.value,
        is_verify: verificationFilter.value,
        'countries[]': countryFilter.value,
        'roles[]': roleFilter.value,
      });
    } catch (err) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('fetchingUsersListError'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      });
    }
  };

  const refetchData = async () => {
    refUserListTable.value.refresh();
    await fetchUsers();
  };

  watch([currentPage, sortBy, perPage, isSortDirDesc, searchQuery, roleFilter, countryFilter, verificationFilter], async () => {
    await refetchData();
  });

  const verifyUser = async user => {
    try {
      await store.dispatch('users/verify', user);

      if (refUserListTable.value) {
        await refetchData();
      }
    } catch (err) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('verifyingUserError'),
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      });
    }
  };

  const getCountryById = id => store.getters['app/getCountryById'](id);
  const getCountries = computed(() => store.state.app.availableCountries);

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserActivationVariant = ({ roles }) => (hasVerifiedRole(roles) ? 'success' : 'warning');

  const resolveUserActivationText = ({ roles }) => (hasVerifiedRole(roles) ? i18n.t('activated') : i18n.t('pending'));

  const resolveUserActivationActionVariant = ({ roles }) => (hasVerifiedRole(roles) ? 'warning' : 'success');

  const resolveUserActivationActionText = ({ roles }) => (hasVerifiedRole(roles) ? i18n.t('unVerify') : i18n.t('verify'));

  const resolveUserActivationActionIcon = () => 'CheckIcon';

  const resolveCompanyDetails = data => {
    const { company } = data;
    const label = company?.company_name ? i18n.t('yes') : i18n.t('no');
    const variant = company?.company_name ? 'success' : 'danger';
    return { label, variant };
  };

  const resolveUserRoleText = ({ roles }) => roles.map(({ name }) => name).filter(name => name !== VERIFIED_ROLE).join(', ');

  const resolveUserCompany = data => {
    const companyName = data?.company?.company_name || '';
    const companyCountry = data?.profile?.country?.name || '';
    return { companyName, companyCountry };
  };

  const countryOptions = computed(() => getCountries.value.map(country => ({ value: String(country.id).valueOf(), label: country.name })));

  return {
    items,
    totalItems,
    fetchUsers,
    verifyUser,
    tableColumns,
    refUserListTable,

    resolveUserActivationVariant,
    resolveUserActivationText,
    resolveUserRoleText,
    resolveUserActivationActionVariant,
    resolveUserActivationActionText,
    resolveUserActivationActionIcon,
    resolveCompanyDetails,
    countryOptions,

    resolveUserCompany,

    refetchData,
    getCountryById,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    // Extra Filters
    roleFilter,
    countryFilter,
    verificationFilter,
  };
}
