<template>
  <div>
    <div
      v-for="(item, i) in documents"
      :key="i"
      class="document-preview d-flex"
      :class="{ _active: hoveredDocument === i }"
      @mouseover="hoveredDocument = i"
      @mouseleave="hoveredDocument = null"
    >
      <div class="d-flex justify-content-between w-100 flex-wrap">
        <div
          class="d-flex align-items-center mw-100 my-2 doc-name"
          :class="`${classesList}`"
        >
          <div class="mr-1">
            <feather-icon
              icon="FileTextIcon"
              size="36"
            />
          </div>
          <div class="mw-100">
            <div class="d-flex flex-column">
              <div class="document-preview-type">
                {{ getDocumentName(item) }}
              </div>
              <span class="text-muted">
                {{ getDate(item) }}
              </span>
            </div>
          </div>
        </div>
        <div
          class="d-flex align-items-center justify-content-end document-preview-buttons"
        >
          <b-button
            target="_blank"
            class="mr-1"
            size="sm"
            @click="getPath(item)"
          >
            <feather-icon
              icon="DownloadIcon"
              size="18"
            />
          </b-button>
          <b-button
            size="sm"
            @click="getPath(item)"
          >
            <feather-icon
              icon="EyeIcon"
              size="18"
            />
          </b-button>
          <b-button
            v-if="isRemovable"
            size="sm"
            class="ml-1"
            @click="showDeleteConfirm(item)"
          >
            <feather-icon
              icon="XIcon"
              size="18"
            />
          </b-button>
        </div>
      </div>
    </div>

    <confirm-modal
      :modal-visible="deleteConfirmVisible"
      :modal-message="confirmMessage"
      :ok-text="okText"
      :cancel-text="cancelText"
      @modal-hidden="deleteConfirmVisible = false"
      @yes-clicked="removeDoc"
      @no-clicked="deleteConfirmVisible = false"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import confirmModal from '@/views/apps/components/modals/confirmModal.vue';
import i18n from '@/libs/i18n';

export default {
  components: {
    confirmModal,
    BButton,
  },
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    isReceipt: {
      type: Boolean,
      default: false,
    },
    isRemovable: {
      type: Boolean,
      default: true,
    },
    classesList: {
      type: String,
      default: '',
    },
    confirmMessage: {
      type: String,
      default: String(i18n.t('deleteFileConfirmMessage')),
    },
    okText: {
      type: String,
      default: String(i18n.t('yesDelete')),
    },
    cancelText: {
      type: String,
      default: String(i18n.t('noCancel')),
    },
  },
  data: () => ({
    hoveredDocument: null,
    deleteConfirmVisible: false,
    itemIdToRemove: null,
  }),
  methods: {
    async getPath(item) {
      console.log('TransactionDocumentsList - getPath');
      console.log(item);
      console.log(item.id);
      let url = null;

      if (['purchase', 'delivery', 'clearance'].includes(item.type)) {
        url = item?.url;
      } else {
        const getUrl = await this.$store.dispatch('deal/getDocument', item.id);
        url = URL.createObjectURL(getUrl.data);
      }

      window.open(url, '_blank');
    },
    getDocumentName(document) {
      if (document.type === 'supply_contract') return this.$t('supplyContract');
      if (document.type === 'packing') return this.$t('packingInfo');
      if (document.type === 'delivery') return this.$t('deliveryInvoice');
      if (document.type === 'purchase') return this.$t('purchaseInvoice');
      if (document.type === 'clearance') return this.$t('clearanceInvoice');

      return document.name || document.document_name;
    },
    getDate(item) {
      const date = item?.invoice_creation_date || item?.created_at;
      if (date) {
        const newDate = new Date(date);
        const day = newDate.getDate() < 9 ? `0${newDate.getDate()}` : newDate.getDate();
        const month = (newDate.getMonth() + 1) <= 9 ? `0${(newDate.getMonth() + 1)}` : newDate.getMonth() + 1;
        return `${day}.${month}.${newDate.getFullYear()}`;
      }
      return '';
    },
    removeDoc() {
      this.$store.dispatch('deal/removeDocument', { documentId: this.itemIdToRemove }).then(() => {
        this.$emit('fetch-deal');
      });

      this.deleteConfirmVisible = false;
    },
    showDeleteConfirm(item) {
      this.itemIdToRemove = item.id;

      this.deleteConfirmVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.doc-name {
  width: 80% !important;
}

.margin-top-12 {
  margin-top: 12px !important;
}

.margin-bottom-12 {
  margin-bottom: 12px !important;
}

.margin-top-bottom-12 {
  margin: 12px 0 !important;
}
</style>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";
.document-preview {
  cursor: pointer;
  overflow: hidden;

  &-buttons {
    opacity: 0;
    transition: all .4s ease;
  }
  &:hover {
    .document-preview-buttons {
      opacity: 1;
    }
  }
  &-type {
    font-weight: bold;
    text-transform: capitalize;
  }
}
</style>
