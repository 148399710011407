<template>
  <div>
    <b-form>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('companyName')"
            label-for="company-name"
          >
            <b-form-input
              id="company-name"
              v-model="updatableData.company_name"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="UNP"
            label-for="company-unp"
          >
            <b-form-input
              id="company-unp"
              v-model="updatableData.unp"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('legalAddress')"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="updatableData.legal_address"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('taxNumber')"
            label-for="tax"
          >
            <b-form-input
              id="tax"
              v-model="updatableData.tax_number"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Gender -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('attachRegistrationCertificate')"
            label-for="e-seal"
          >
            <template v-if="!updatableData.e_seal">
              <b-form-file
                id="e-seal"
                @change="updateTempSign"
              />
              <div
                v-if="tempSignImage"
                class="mt-2"
              >
                <b-button
                  variant="success"
                  class="mr-1"
                  @click="uploadTempSign"
                >
                  {{ $t('upload') }}
                </b-button>
                <b-button
                  variant="warning"
                  @click="clearTempSign"
                >
                  {{ $t('clear') }}
                </b-button>
              </div>
            </template>
            <template v-else>
              <div class="seal-preview">
                <feather-icon
                  size="32"
                  icon="XIcon"
                  class="cursor-pointer eSeal-remove"
                  @click="removeSign"
                />
                <b-img
                  class="seal-preview-image"
                  :src="updatableData.e_seal_url"
                  width="100px"
                  alt="Sign"
                />
              </div>
              <a
                :href="updatableData.e_seal_url"
                target="_blank"
              >
                {{ updatableData.e_seal_name }}
              </a>
            </template>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="d-flex mt-2">
        <h4 class="mb-0">
          {{ $t('bankInfo') }}
        </h4>
      </div>

      <b-row class="mt-1">
        <!-- Field: Address Line 1 -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('bankName')"
            label-for="bank-name"
          >
            <b-form-input
              id="bank-name"
              v-model="updatableData.bank_name"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('bankAddress')"
            label-for="bank-address"
          >
            <b-form-input
              id="bank-address"
              v-model="updatableData.bank_address"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Swift/BIC"
            label-for="swift"
          >
            <b-form-input
              id="swift"
              v-model="updatableData.swift"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('accountNumber')"
            label-for="iban"
          >
            <b-form-input
              id="iban"
              v-model="updatableData.iban"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="d-flex mt-2">
        <h4 class="mb-0">
          {{ $t('companyDataAlert') }}
        </h4>
      </div>
      <b-row class="mt-1">
        <b-col
          cols="12"
        >
          <b-form-group>
            <b-form-checkbox
              id="privacy-policy"
              v-model="updatableData.is_agreement"
            >
              {{ $t('privacyAgree') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <b-button
            :disabled="isSaveDisabled"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateUserData"
          >
            {{ $t('saveChanges') }}
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="refreshUpdatableData"
          >
            {{ $t('reset') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormCheckbox, BFormFile, BImg,
} from 'bootstrap-vue';
import { ref, computed, watch } from '@vue/composition-api';

import axios from '@/libs/axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormCheckbox, BFormFile, BImg,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tempSignImage: null,
    };
  },
  methods: {
    updateTempSign(e) {
      const [file] = e.target.files;
      if (!file) {
        return;
      }

      this.tempSignImage = file;
    },
    async uploadTempSign() {
      if (!this.tempSignImage) {
        return;
      }

      const form = new FormData();
      form.set('certificate', this.tempSignImage);

      try {
        const { data: { data } } = await axios.post('/legals/certificate', form);

        this.updatableData = {
          ...this.updatableData,
          e_seal: data.id,
          e_seal_url: data.certificate,
          e_seal_name: 'Certificate',
        };

        this.clearTempSign();
      } catch (e) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Error upload image',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    clearTempSign() {
      this.tempSignImage = null;
    },
    removeSign() {
      this.clearTempSign();
      this.updatableData.e_seal = '';
      this.updatableData.e_seal_url = '';
      this.updatableData.e_seal_name = '';
    },
  },
  setup(props, { emit }) {
    const updatableData = ref(null);

    const refreshUpdatableData = () => {
      const { company, is_agreement } = props.userData;

      updatableData.value = {
        company_name: company?.company_name || '',
        unp: company?.unp || '',
        legal_address: company?.address || '',
        tax_number: company?.tax_number || '',
        e_seal: company?.legal_certificate?.id || '',
        e_seal_url: company?.legal_certificate?.url || '',
        e_seal_name: company?.legal_certificate?.name || '',
        bank_name: company?.bank_name || '',
        bank_address: company?.bank_address || '',
        swift: company?.swift || '',
        iban: company?.bank_account || '',
        is_agreement,
      };
    };

    const isSaveDisabled = computed(() => !updatableData?.value?.company_name?.length
        || !updatableData?.value.unp?.length
        || !updatableData?.value.legal_address.length
        || !updatableData?.value.tax_number?.length
        || !updatableData?.value.bank_name?.length
        || !updatableData?.value.bank_address?.length
        || !updatableData?.value.swift?.length
        || !updatableData?.value.iban?.length
        || !updatableData?.value.is_agreement);

    refreshUpdatableData();
    watch(() => props.userData, refreshUpdatableData);

    const updateUserData = async () => {
      const {
        company_name,
        iban,
        bank_name,
        bank_address,
        unp,
        e_seal,
        legal_address,
        swift,
        tax_number,
        is_agreement,
      } = updatableData.value;

      const data = {
        'company[company_name]': company_name,
        'company[bank_account]': iban,
        'company[bank_name]': bank_name,
        'company[bank_address]': bank_address,
        'company[unp]': unp,
        'company[sign_image_id]': e_seal,
        'company[address]': legal_address,
        'company[swift]': swift,
        'company[tax_number]': tax_number,
        is_agreement: Number(is_agreement),
      };

      const params = new URLSearchParams(data);
      emit('updateData', params);
    };

    const toast = useToast();

    return {
      refreshUpdatableData,
      updateUserData,
      updatableData,
      isSaveDisabled,
      toast,
    };
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
.seal-preview {
  .eSeal-remove {
    top: 32px;
    left: 48px;
    opacity: 0;
    transition: all .4s ease;
    position: absolute;
    z-index: 1;
    &-image {
      user-select: none;
    }
  }
  &:hover {
    .eSeal-remove {
      opacity: 1;
    }
  }
}
</style>
