<template>
  <div>
    <b-form>
      <!--      <b-row>-->
      <!--        <b-col>-->
      <!--          <b-button variant="primary">-->
      <!--            Reset Password-->
      <!--          </b-button>-->
      <!--        </b-col>-->
      <!--      </b-row>-->

      <b-row class="mt-1">
        <b-col
          v-if="withOldPassword"
          md="4"
        >
          <b-form-group
            label-for="old-password"
            :label="$t('oldPassword')"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="old-password"
                v-model="updatableData.old_password"
                :type="passwordFieldTypeOld"
                name="old-password"
                :placeholder="$t('oldPassword')"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col :md="withOldPassword ? 4 : 6">
          <b-form-group
            label-for="new-password"
            :label="$t('newPassword')"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="new-password"
                v-model="updatableData.password"
                :type="passwordFieldTypeNew"
                name="new-password"
                :placeholder="$t('newPassword')"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col :md="withOldPassword ? 4 : 6">
          <b-form-group
            label-for="retype-new-password"
            :label="$t('retypeNewPassword')"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="retype-new-password"
                v-model="updatableData.password_confirmation"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                :placeholder="$t('retypeNewPassword')"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            variant="primary"
            :disabled="isSaveDisabled"
            class="mt-1 mr-1"
            @click="updateUserData"
          >
            Save changes
          </b-button>
          <b-button
            type="reset"
            variant="outline-secondary"
            class="mt-1"
            @click="refreshUpdatableData"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue';
import { ref, computed } from '@vue/composition-api';

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend,
  },
  props: {
    withOldPassword: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const updatableData = ref(null);

    const isSaveDisabled = computed(() => !updatableData.value.password?.length
        || !updatableData.value.password_confirmation?.length
        || (props.withOldPassword && !updatableData.value.old_password?.length));
    const refreshUpdatableData = () => {
      updatableData.value = {
        old_password: '',
        password: '',
        password_confirmation: '',
      };
    };

    refreshUpdatableData();

    const updateUserData = () => {
      const data = {
        old_password: updatableData.value.old_password,
        password: updatableData.value.password,
        password_confirmation: updatableData.value.password_confirmation,
      };

      const params = new URLSearchParams(data);
      emit('updateData', params);
    };

    const passwordFieldTypeOld = ref('password');
    const passwordFieldTypeNew = ref('password');
    const passwordFieldTypeRetype = ref('password');

    const passwordToggleIconOld = computed(() => (passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'));
    const passwordToggleIconNew = computed(() => (passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'));
    const passwordToggleIconRetype = computed(() => (passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'));

    const togglePasswordOld = () => {
      passwordFieldTypeOld.value = passwordFieldTypeOld.value === 'password' ? 'text' : 'password';
    };
    const togglePasswordNew = () => {
      passwordFieldTypeNew.value = passwordFieldTypeNew.value === 'password' ? 'text' : 'password';
    };
    const togglePasswordRetype = () => {
      passwordFieldTypeRetype.value = passwordFieldTypeRetype.value === 'password' ? 'text' : 'password';
    };

    return {
      isSaveDisabled,
      updatableData,
      passwordFieldTypeOld,
      passwordFieldTypeNew,
      passwordFieldTypeRetype,

      passwordToggleIconOld,
      passwordToggleIconNew,
      passwordToggleIconRetype,

      togglePasswordOld,
      togglePasswordNew,
      togglePasswordRetype,
      updateUserData,
      refreshUpdatableData,
    };
  },
};
</script>

<style>

</style>
