<template>
  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <feather-icon
        class="mr-1"
        icon="FileTextIcon"
        size="25"
      />
      <span class="mr-1">
        {{ file.name }}
      </span>
    </div>
    <div
      class="d-flex align-items-center justify-content-end"
    >
      <b-button
        target="_blank"
        class="mr-1"
        size="sm"
        @click="openFile"
      >
        <feather-icon
          icon="DownloadIcon"
          size="18"
        />
      </b-button>
      <b-button
        size="sm"
        @click="openFile"
      >
        <feather-icon
          icon="EyeIcon"
          size="18"
        />
      </b-button>
      <b-button
        size="sm"
        class="ml-1"
        @click="$emit('remove-file')"
      >
        <feather-icon
          icon="XIcon"
          size="18"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import { inputImageRenderer } from '@core/comp-functions/forms/form-utils';

export default {
  name: 'UploadedFile',
  components: { BButton },
  props: {
    file: {
      type: File,
      required: true,
    },
  },
  methods: {
    openFile() {
      inputImageRenderer(this.file, data => {
        fetch(data)
          .then(res => res.blob())
          .then(res => window.open(URL.createObjectURL(res), '_blank'));
      });
    },
  },
};
</script>

<style scoped>

</style>
