<template>
  <div class="drop-zone-field">
    <div class="cursor-pointer">
      <vue-dropzone
        v-show="showDropZone"
        id="payments-dropzone"
        ref="myVueDropzone"
        :use-custom-slot="true"
        :options="options"
        @vdropzone-file-added="addingFile"
      >
        <div class="d-flex flex-column align-items-center justify-content-center drop-zone">
          <feather-icon
            class="mb-2"
            icon="UploadIcon"
            size="24"
          />
          <slot name="description">
            <div class="d-flex flex-column align-items-center">
              <h6 class="my-1">
                {{ $t('dropDocumentsToUpload') }}
              </h6>
              <span>
                {{ $t('allDocFormatsAllowed') }}
              </span>
            </div>
          </slot>
        </div>
      </vue-dropzone>
    </div>

    <div v-if="files.length">
      <div class="uploaded-file-container">
        <div class="uploaded-file-div">
          <div>
            <span>{{ $t('uploadedFile') }}:</span>
          </div>
          <div class="file-name">
            <b-form-input
              v-model="fileName"
              class="file-name"
              :style="{width: ((fileName.length * 9) - 28 > 40 ? fileName.length * 9 + 28 : 80) + 'px'}"
            />
            <span class="file-type">
              {{ fileType }}
            </span>
          </div>
        </div>

        <div class="buttons-div">
          <div class="show-button">
            <b-button
              target="_blank"
              size="sm"
              variant="outline-primary"
              @click="showUploadedFile(files[0])"
            >
              <feather-icon
                icon="EyeIcon"
                size="18"
              />
            </b-button>
            <b-button
              size="sm"
              class="ml-1"
              variant="outline-primary"
              @click="deleteConfirmVisible = true"
            >
              <feather-icon
                icon="XIcon"
                size="18"
              />
            </b-button>
          </div>
          <b-button
            variant="primary"
            class="ml-1"
            @click="handleSaveDocument"
          >
            <span>{{ saveButtonText }}</span>
          </b-button>
        </div>
      </div>
    </div>

    <slot name="info-snackbar" />

    <slot
      name="footer"
      :reset="reset"
    />

    <confirm-modal
      :modal-visible="deleteConfirmVisible"
      :modal-message="confirmMessage"
      :ok-text="okText"
      :cancel-text="cancelText"
      @modal-hidden="deleteConfirmVisible = false"
      @yes-clicked="removeFile(0)"
      @no-clicked="deleteConfirmVisible = false"
    />
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import { BFormInput, BButton } from 'bootstrap-vue';
import confirmModal from '@/views/apps/components/modals/confirmModal.vue';
import i18n from '@/libs/i18n';

export default {
  components: {
    vueDropzone: vue2Dropzone,
    confirmModal,
    BFormInput,
    BButton,
  },
  props: {
    options: {
      type: Object,
      default: () => ({
        previewsContainer: false,
        autoProcessQueue: false,
        url: 'localhost',
      }),
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    confirmMessage: {
      type: String,
      default: String(i18n.t('deleteFileConfirmMessage')),
    },
    okText: {
      type: String,
      default: String(i18n.t('yesDelete')),
    },
    cancelText: {
      type: String,
      default: String(i18n.t('noCancel')),
    },
    saveButtonText: {
      type: String,
      default: String(i18n.t('saveFile')),
    },
  },
  data: () => ({
    showDropZone: true,
    fileName: 'bla bla',
    fileType: null,
    files: [],
    deleteConfirmVisible: false,
  }),
  methods: {
    splitString(input) {
      const regex = /(.*)\.(.*)/;
      const match = input.match(regex);

      if (match) {
        // Return the first part and include the period in the second part
        return [match[1].trim(), `.${match[2].trim()}`];
      }

      return [input, ''];
    },
    addingFile(file) {
      console.log('DropZone addingFile');
      if (!this.isMultiple) {
        this.files = [file];
      } else {
        this.files.push(file);
      }

      [this.fileName, this.fileType] = this.splitString(this.files[0].name);
    },
    reset() {
      this.files = [];
      this.$emit('reset');
    },
    handleSaveDocument() {
      this.$emit(
        'add-file',
        [new File([this.files[0]], this.fileName + this.fileType, { type: this.files[0].type })],
      );
    },
    showUploadedFile(file) {
      window.open(URL.createObjectURL(file), '_blank');
    },
    removeFile(index) {
      this.files.splice(index, 1);

      this.deleteConfirmVisible = false;
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";
.dropzone {
  transition: all .2s ease;
}
.dz-drag-hover {
  background-color: rgba(235, 233, 241, .3);
}
.drop-zone {
  border:  2px dashed $table-border-color;
  padding: 1rem;
  border-radius: 5px;
}
.dark-layout {
  .drop-zone {
    border:  2px dashed $theme-dark-widget-bg;
  }
}
</style>

<style lang="scss" scoped>
.file-name {
  display: inline-block;
  max-width: 75%;
  margin-left: 10px;
}

.file-type {
  margin-left: 5px;
}

.drop-zone-field {
  background-color: rgba(136,136,138,0.05);
  padding: 15px;
}

.uploaded-file-container {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  font-weight: 500;
}

.uploaded-file-div {
  display: flex;
  align-items: center;
  max-width: 60%;
}

.buttons-div {
  display: flex;
}

.show-button {
  display: flex;
  align-items: center;
}
</style>
